// extracted by mini-css-extract-plugin
export var content = "styles-module--content--SJnLG";
export var thanks = "styles-module--thanks--278cp";
export var bold = "styles-module--bold--2LFDN";
export var field = "styles-module--field--CtWWR";
export var label = "styles-module--label--39M3Z";
export var control = "styles-module--control--1wV2_";
export var menu = "styles-module--menu--24oyn";
export var input = "styles-module--input--23lmK";
export var textarea = "styles-module--textarea--2nysW";
export var button = "styles-module--button--34wUQ";
export var is_link = "styles-module--is_link--3x4ST";
export var mobbtn = "styles-module--mobbtn--1ABMF";