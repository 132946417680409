import React from "react";
import Layout from "../../components/Layout/layout";
import * as styles from "./styles.module.css";

// eslint-disable-next-line
export default () => (
  <Layout>
    <div className={styles.thanks}>
      <h1>Thank you!</h1>
      <p>
        We have received your message and would like to thank you for writing to
        us.
      </p>
      <p> Have a great day!</p>
      <p className={styles.bold}> Team Carbae.in</p>
    </div>
  </Layout>
);
